import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/Layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Privacy Policy`}</h1>
    <p>{`This policy applies to all information consumed, collected, or submitted on Harbor’s apps for iPhone, iPad, and any other devices and platforms.`}</p>
    <h2>{`Information we collect`}</h2>
    <p>{`Our server software may store basic technical information, such as your IP address, in temporary memory or logs. Harbor has server software which hosts a searchable directory of RSS and RSS-like feeds and websites.`}</p>
    <p>{`In the event of a software crash or fault during your use of the Harbor app, we collect anonymous and non-personal crash information for reliability purposes, which we may share with third-parties.`}</p>
    <h2>{`Third-party content and links`}</h2>
    <p>{`Harbor displays content from third-party RSS and RSS-like feeds and websites. The Harbor app accesses and downloads content directly from third-party servers. These may have their own independent privacy policies, and we have no responsibility or liability for their content or activities.`}</p>
    <h2>{`iCloud`}</h2>
    <p>{`Harbor stores some of your data in Apple’s iCloud service, such as a database of your feed subscriptions, to enable some sync features between all devices signed into your Apple ID.`}</p>
    <h2>{`Your Consent`}</h2>
    <p>{`By using our apps, you consent to our privacy policy.`}</p>
    <h2>{`California Online Privacy Protection Act compliance`}</h2>
    <p>{`We comply with the California Online Privacy Protection Act. We therefore will not distribute your personal information to outside parties without your consent.`}</p>
    <h2>{`Children’s Online Privacy Protection Act compliance`}</h2>
    <p>{`We never collect or maintain information at our website from those we actually know are under 13, and no part of our website is structured to attract anyone under 13.`}</p>
    <h2>{`Information for European Union customers`}</h2>
    <p>{`By using Harbor and providing your information, you authorize us to collect, use, and store your information outside of the European Union.`}</p>
    <h2>{`Changes to this policy`}</h2>
    <p>{`If we decide to change our privacy policy, we will post those changes on this page. Summary of changes so far:`}</p>
    <ul>
      <li parentName="ul">{`July 22, 2020: First published.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      